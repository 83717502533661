<template>
  <div class="loginDialogBox">
    <img
      @click="backHome"
      class="logo cursor"
      src="../../assets/img/newLogo.png"
      alt
      srcset
    />
    <div class="tabs">
      <el-tabs v-model="activeLabel">
        <el-tab-pane label="验证码登录" name="code" class="codeLogin">
          <el-form
            :label-position="'top'"
            :model="codeForm"
            ref="codeForm"
            :rules="phoneRules"
          >
            <el-form-item label prop="phone">
              <el-input
                placeholder="请输入手机号"
                v-model="codeForm.phone"
                class="input-with-select"
                type="number"
              >
                <el-select
                  v-model="codeForm.areaCode"
                  slot="prepend"
                  popper-class="registerAreaCodeList"
                  filterable
                  :placeholder="$t('pleaseSelect')"
                  :filter-method="areaCodeFilter"
                >
                  <el-option
                    v-for="(item, index) in areaCodeList"
                    :key="item.area_code + index + ''"
                    :value="item.area_code"
                    :title="item.country | areacodeTitleFormat(LOCALE)"
                  >
                    <p class="area_code">{{ item.area_code }}</p>
                    <p class="country textOverflow">
                      {{ item.country | areacodeAddressFormat(LOCALE) }}
                    </p>
                  </el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item prop="code">
              <el-input
                style="width: 200px"
                placeholder="请输入验证码"
                type="text"
                v-model="codeForm.code"
                @keyup.enter.native="sign"
                autocomplete="off"
                nowritten
              ></el-input>
              <el-button
                class="fr getCodeBtn"
                @click="getCode"
                :disabled="codeBtnDisabled"
                >{{ codeText }}</el-button
              >
              <div class="custom_error_tip" v-show="phoneErrorShow">
                {{ $t("invalidphoneAccount") }}
              </div>
              <div class="custom_error_tip" v-show="phoneDisabledShow">
                {{ $t("accountDisabledTip") }}
              </div>
              <div class="custom_error_tip" v-show="notRegisterShow">
                账号未注册
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="密码登录" name="password" class="passwordLogin">
          <el-form
            :model="passwordForm"
            :rules="emailRules"
            ref="passwordForm"
            :label-position="'top'"
            label-width="80px"
          >
            <el-form-item label prop="emailPhone">
              <el-input
                placeholder="手机号/邮箱"
                v-model="passwordForm.emailPhone"
                nowritten
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                placeholder="请输入密码"
                type="password"
                v-model="passwordForm.password"
                nowritten
                :show-password="true"
                autocomplete="off"
                @keyup.enter.native="sign"
              ></el-input>
              <div class="custom_error_tip" v-show="cetshow">
                {{ $t("notActiveTip1") }}
                <a @click="getNewActiveLink" class="getEmailBtn">{{
                  $t("clickHere")
                }}</a>
                {{ $t("notActiveTip2") }}
              </div>
              <div class="custom_error_tip" v-show="emailErrorShow">
                {{ $t("invalidEmailAccount") }}
              </div>
              <div class="custom_error_tip" v-show="emailDisabledShow">
                {{ $t("accountDisabledTip") }}
              </div>
              <div class="custom_error_tip" v-show="mobileErrorShow">
                {{ $t("invalidphoneAccount") }}
              </div>
              <div class="custom_error_tip" v-show="mobileDisabledShow">
                {{ $t("accountDisabledTip") }}
              </div>
              <div class="custom_error_tip" v-show="notRegisterShow">
                账号未注册
              </div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div></div>
    <div class="text_link">
      <div>
        <el-checkbox v-model="sevenChecked">7天内自动登录</el-checkbox>
      </div>
      <div>
        <el-button
          type="primary"
          class="loginBut"
          size="small"
          @click="sign"
          :loading="signLoading"
          >登录</el-button
        >
      </div>
      <div class="forgetPassword">
        <a @click="toForget" class="cursor">忘记密码？</a>
        <!-- <router-link to="/forgetPassword"> 忘记密码？ </router-link> -->
      </div>
      <div class="toReigster">
        <a @click="toRegister" class="registerText"> 免费注册 </a>
      </div>
    </div>

    <p class="botTxt clearfix"></p>
    <!-- <loginFooter></loginFooter> -->
  </div>
</template>

<script>
import { LCstore } from "~/baseUtils/storage";
import {
  validateTelephone,
  validateEmailOrTelephone,
} from "~/baseUtils/validate";
import { checkBasicInfo } from "~hbs/utils";
import { areaCodeList } from "~/baseUtils/AddressConfig";
import loginFooter from "@/projects/tradeFederation/components/common/loginFooter";
export default {
  props: {
    isInquiry: {
      type: Boolean,
      default: false,
    },
    inquiryParams: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    loginFooter,
  },
  data() {
    return {
      activeLabel: "code",
      passwordForm: {
        emailPhone: "",
        password: "",
      },
      codeText: "获取验证码",
      sevenChecked: false,
      areaCodeList,
      areaCodeListCopy: [].concat(areaCodeList),
      cetshow: false,
      signLoading: false,
      emailErrorShow: false,
      phoneErrorShow: false,
      mobileErrorShow: false,
      emailDisabledShow: false,
      phoneDisabledShow: false,
      mobileDisabledShow: false,
      notRegisterShow: false,
      codeBtnDisabled: false,
      checkCode: false,
      codeForm: {
        phone: "",
        code: "",
        areaCode: "+86",
      },
      emailRules: {
        emailPhone: {
          validator: async (rule, value, callback) => {
            let activeLabel;
            let isPhone = validateTelephone(this.passwordForm.emailPhone);
            if (isPhone) {
              activeLabel = "手机号";
            } else {
              activeLabel = "邮箱";
            }
            if (value == "") {
              callback(new Error(" "));
            } else if (!validateEmailOrTelephone(value)) {
              this.codeBtnDisabled = true;
              callback(`${activeLabel}号格式错误`);
            } else {
            }
          },
          trigger: "blur",
        },
        password: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      },
      phoneRules: {
        phone: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (!validateTelephone(value)) {
              this.codeBtnDisabled = true;
              callback("手机号格式错误");
            } else {
            }
          },
          trigger: "blur",
        },
        code: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else {
              if (this.checkCode) {
                let result = await this.$store.dispatch(
                  "baseStore/checkVerifyCode",
                  {
                    area_code: this.codeForm.areaCode.replace("+", ""),
                    mobile: `${this.codeForm.phone}`,
                    ctype: 23,
                    code: this.codeForm.code,
                  }
                );
                this.checkCode = false;
                if (result.success) {
                  if (result.data) {
                    callback();
                  } else {
                    callback(new Error("验证码错误"));
                  }
                } else {
                  callback(new Error(" "));
                  this.$seTip();
                }
              } else {
                callback();
              }
            }
          },
          trigger: "blur",
        },
      },
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {
    passwordForm: {
      deep: true,
      handler() {
        this.clearErrorTip();
      },
    },
    codeForm: {
      deep: true,
      handler() {
        this.clearErrorTip();
      },
    },
  },
  methods: {
    async getCode() {
      if (this.codeForm.phone == "") {
        this.$message.warning("请输入手机号");
        return;
      }
      if (parseInt(this.codeText) == this.codeText) {
        return;
      }
      try {
        let result = { success: true };
        await this.$store.dispatch("baseStore/getVerifyCode", {
          area_code: this.codeForm.areaCode.replace("+", ""),
          mobile: `${this.codeForm.phone}`,
          ctype: 23,
        });
        if (result.success) {
          this.$message.success("验证码发送成功");
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.codeText = TIME_COUNT;
            this.codeBtnDisabled = true;
            this.timer = setInterval(() => {
              if (this.codeText > 0 && this.codeText <= TIME_COUNT) {
                this.codeText--;
              } else {
                this.codeText = "获取验证码";
                clearInterval(this.timer);
                this.timer = null;
                this.codeBtnDisabled = false;
              }
            }, 1000);
          }
        } else {
          this.$seTip();
        }
      } catch (e) {
        this.$seTip();
      }
    },
    backHome() {
      this.$router.push("/");
    },
    areaCodeFilter(value) {
      if (value) {
        this.areaCodeList = this.areaCodeListCopy
          .filter((item) => {
            return item.area_code.indexOf(value) == 1;
          })
          .sort((a, b) => {
            return parseInt(a.area_code) - parseInt(b.area_code);
          });
      } else {
        this.areaCodeList = this.areaCodeListCopy;
      }
    },
    sign() {
      let activeLabel;
      if (this.activeLabel == "code") {
        activeLabel = "phone";
      } else {
        let isPhoneShow = validateTelephone(this.passwordForm.emailPhone);
        if (isPhoneShow) {
          activeLabel = "mobile";
        } else {
          activeLabel = "email";
        }
      }
      this.checkCode = true;
      this[`${activeLabel}ErrorShow`] = false;
      this[`${activeLabel}DisabledShow`] = false;
      this.$refs[`${this.activeLabel}Form`].validate(async (valid) => {
        if (valid) {
          this.signLoading = true;
          let isPassword = activeLabel != "phone";
          let params = {
            source: this.PJSource,
            seven: this.sevenChecked,
          };
          if (isPassword) {
            params.password = this.passwordForm.password;
            let isPhone = validateTelephone(this.passwordForm.emailPhone);
            if (isPhone) {
              params.mobile = `+86${this.passwordForm.emailPhone}`;
            } else {
              params.email = this.passwordForm.emailPhone;
            }
          } else {
            params.mobile = `${this.codeForm.areaCode}${this.codeForm.phone}`;
            params.login_type = 2;
          }
          try {
            let result = await this.$store.dispatch(
              "baseStore/baseSign_Login",
              params
            );
            if (result.success) {
              this.$GLOBALEVENT.$emit("CLOSEGLOBALLOGIN");
              //this.INFO_MATION();
              let checkType = checkBasicInfo(this.USER_INFO, this.LOCALE);
              if (!checkType.flag) {
                this.$router.push({
                  path: "/basicInfo",
                  query: {
                    parameter: this._encode({
                      need_type: checkType.needType,
                      user_id: this.USER_INFO.id,
                    }),
                  },
                });
              } else {
                this.$message.success(this.$t("loginSuccess"));
                this.$emit("loginSuccess", params);
              }
            } else {
              if (
                result.errorcode == 1002 ||
                result.errorcode == 1004 ||
                result.errorcode == 1014 ||
                result.errorcode == 1024 ||
                result.errorcode == 1039
              ) {
                this[`${activeLabel}ErrorShow`] = true;
              }
              if (result.errorcode == 1003 || result.errorcode == 1005) {
                this.cetshow = true;
              }
              if (result.errorcode == 1013) {
                this[`${activeLabel}DisabledShow`] = true;
              }

              if (result.errorcode == 1012) {
                this.notRegisterShow = true;
              }
            }
            this.signLoading = false;
          } catch (e) {
            this.signLoading = false;
            this.$seTip();
          }
        }
      });
    },
    async getNewActiveLink() {
      try {
        let result = await this.$store.dispatch(
          "API_user/user_getActiveMailRegain",
          {
            email: this.passwordForm.emailPhone,
            source: this.PJSource,
          }
        );
        if (result.success) {
          //this.$message.success(this.$t(""));
          this.$osTip();
          this.cetshow = false;
        } else {
          this.$seTip();
        }
      } catch (e) {
        this.$seTip();
      }
    },
    clearErrorTip() {
      this.emailErrorShow = false;
      this.phoneErrorShow = false;
      this.mobileErrorShow = false;
      this.mobileDisabledShow = false;
      this.emailDisabledShow = false;
      this.phoneDisabledShow = false;
      this.notRegisterShow = false;
      this.cetshow = false;
    },
    toRegister() {
      this.$GLOBALEVENT.$emit("CLOSEGLOBALLOGIN");
      this.$router.push("/signup");
    },
    toForget() {
      this.$GLOBALEVENT.$emit("CLOSEGLOBALLOGIN");
      this.$router.push("/forgetPassword");
    },
  },
};
</script>

<style scoped lang="less">
.loginDialogBox {
  width: 400px;
  // height: 490px;
  padding-bottom: 24px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  border: 1px solid #979797;

  padding-top: 24px;
  margin: 0 auto;
  .getEmailBtn {
    color: #4a5a81;
    cursor: pointer;
  }
  .logo {
    height: 48px;
    margin: 0 auto;
    display: block;
  }
  .tabs {
    padding: 20px;
    padding-bottom: 0px;
    .el-tabs {
      width: 325px;
      margin: 0 auto;
    }
  }
  .emailLogin {
    padding: 0 16px;
    padding-top: 24px;
    padding-bottom: 6px;
  }
  .botTxt {
    padding: 0 50px;
    .fl {
      color: #0083f6;
      cursor: pointer;
    }
    .fr {
      color: #999;
      cursor: pointer;
    }
  }
  .loginBut {
    width: 325px;
    display: block;
    height: 40px;
    margin: 24px auto 12px;
    background: #4a5a81;
    border: 0 none;
    font-size: 16px;
  }
  .registerText {
    color: #4a5a81;
    cursor: pointer;
  }
  .text_link {
    width: 325px;
    font-size: 14px;
    margin: 0 auto 24px;
    height: 22px;
    .forgetPassword {
      float: left;
      a {
        color: #909399;
      }
    }
    .toReigster {
      float: right;
    }
  }
  .getCodeBtn {
    width: 112px;
    color: #4a5a81;
    &:hover,
    &:focus {
      color: #4a5a81;
      // border-color: #ffb9bb;
      // background-color: #fee;
    }
  }
  /deep/ .el-checkbox {
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #4a5a81;
    }
    &.is-checked {
      .el-checkbox__inner {
        background-color: #4a5a81;
        border-color: #4a5a81;
      }
    }
  }
}

.loginDialogBox /deep/ .el-tabs__header {
  .el-tabs__active-bar {
    background-color: #4a5a81;
  }
  .el-tabs__item {
    font-size: 16px;
    color: #979797;
    &:hover {
      color: #4a5a81;
    }
    &.is-active {
      color: #4a5a81;
    }
  }
  margin: 0;
}
.custom_error_tip {
  color: #f56c6c;
  line-height: 20px;
}
.loginDialogBox /deep/ .el-tabs__content {
  margin-top: 15px;
}
.loginDialogBox /deep/ .el-tabs__nav {
  transform: translateX(70.5px) !important;
}
.loginDialogBox.zh /deep/ .el-tabs__nav {
  transform: translateX(104px) !important;
}
</style>
