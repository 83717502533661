import { render, staticRenderFns } from "./backPageTop.vue?vue&type=template&id=4992d495&scoped=true"
import script from "./backPageTop.vue?vue&type=script&lang=js"
export * from "./backPageTop.vue?vue&type=script&lang=js"
import style0 from "./backPageTop.vue?vue&type=style&index=0&id=4992d495&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4992d495",
  null
  
)

export default component.exports