<template>
  <div class="siffaPageBottom">
    <div class="content_area ">
      <div class="contact_follow clearfix">
        <div class="contact">
          <h2>联系我们</h2>
          <!-- <ul>
            <li v-for="(item, i) in contact_arr" :key="i">
              {{ item.lable }}{{ item.content }}
            </li>
          </ul> -->
          <div class="contact-wrap">
            <ul class="concatTel">
              <li v-for="(item, i) in tel" :key="i">
                {{ item }}
              </li>
            </ul>
            <!-- <ul class="concatTel">
              <li v-for="(item, i) in tel2" :key="i">
                {{ item }}
              </li>
            </ul> -->
          </div>
        </div>

        <!-- <div class="follow">
          <h2>关注我们</h2>
          <ul>
            <li v-for="(item, i) in QRcode_arr" :key="i">
             
              <el-image :src="item.url" fit="contain"></el-image>
              <div class="tc">{{ item.name }}</div>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
    <div class="bottom tc">
      <!-- <bottomContent></bottomContent> -->
      2020 AI Logistics Technology(Shanghai) Co., Ltd. 技术支持由链韵物流科技（上海）有限公司提供
      <a href="https://ailaworld.com.cn/"></a>
    </div>
  </div>
</template>

<script>
import bottomContent from "~hbs/components/layouts/bottomContent";
export default {
  components: { bottomContent },
  data() {
    return {
      Links_arr: [
        {
          lable: "上海海关",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href.png",
          href: "http://shanghai.customs.gov.cn/",
        },
        {
          lable: "科越信息",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href1.png",
          href: "http://www.etongguan.com/",
        },
        {
          lable: "上海出入境检验检疫局",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href2.png",
          href: "http://www.shciq.gov.cn/",
        },
        {
          lable: "中国上海",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href3.png",
          href: "http://www.shanghai.gov.cn/",
        },
        {
          lable: "上海市商务委员会",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href4.png",
          href: "https://sww.sh.gov.cn/",
        },
        {
          lable: "海关总署",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href5.png",
          href: "http://www.customs.gov.cn/",
        },
        {
          lable: "AiLa",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href6.png",
          href: "https://www.ailaworld.com.cn",
        },
      ],
      contact_arr: [
        {
          lable: "协会地址：",
          content: "上海市杨树浦路18号（上海航运交易所大楼）2207室",
        },

        {
          lable: "邮箱：",
          content: "service@scba.com.cn",
        },
      ],
      tel: [
        // {
        //   lable: "秘书处总机：",
        //   content: "021-65374744",
        // },
        // {
        //   lable: "入会联系：",
        //   content: "021-68892891",
        // },
        // {
        //   lable: "业务咨询：",
        //   content: "021-68892892",
        // },
        // {
        //   lable: "培训联系：",
        //   content: "021-68892894",
        // },
        // {
        //   lable: "赞助联系：",
        //   content: "021-68892893",
        // },
        // {
        //   lable: "跨境贸易分会联系：",
        //   content: "021-20953528",
        // },
        // {
        //   lable: "空运分会联系：",
        //   content: "021-58102609",
        // },
        "Jack Ruan",
        "13564603581",
        "ruanyh9918@gmail.com",
      ],
      // tel2: [
      //   "云校招秘书 Jackson",
      //   "（+86）02165651265",
      //   "13402033853",
      //   "julebu@cargotrans.com",
      // ],
      QRcode_arr: [
        {
          name: "微信",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/vx.png",
        },
        {
          name: "二维码",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/erweima.jpg",
        },
      ],
    };
  },
  methods: {
    link_click(row) {},
    toHref(item) {
      window.open(item.href);
    },
  },
};
</script>

<style scoped lang="less">
.siffaPageBottom {
  width: 100%;
  background: #4a5a81;
  .friendLink {
    padding: 15px 0px 10px;
    font-size: 16px;
    color: #fff;
  }
  .link_box {
    display: flex;
    li {
      cursor: pointer;
      margin-right: 24px;
      // width: 64px;
      height: 24px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #fff;
      line-height: 22px;
    }
  }
  .concatTel {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    // width: 500px;
    li {
      width: 250px;
      text-align: left;
    }
  }
  .contact_follow {
    padding-top: 42px;
    display: flex;
    h2 {
      font-size: 16px;
      font-family: PingFang-SC-Heavy, PingFang-SC;
      font-weight: 800;
      color: #dcdfe6;
      line-height: 22px;
    }
    .contact {
      padding-bottom: 26px;
      width: 1070px;
      // height: 220px;
      ul {
        padding-top: 26px;
        li {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #dcdfe6;
          line-height: 30px;
        }
      }
    }
    .follow {
      min-width: 200px;
      color: #fff;
      ul {
        display: flex;
        padding-top: 20px;
        li {
          margin-right: 20px;
          width: 130px;
          height: 130px;
          img {
            width: 130px;
            height: 130px;
          }
        }
      }
    }
  }
  .bottom {
    border-top: 1px solid #ececec;
    color: #fff;
    padding-bottom: 10px;
    font-size: 12px;
    a {
      color: #fff;
      font-size: 12px;
    }
  }
}
.contact-wrap{
  display: flex;
}
</style>
