export default {
    namespaced: true,
    actions: {
      
        async getCarList(context, params) {                    //车辆列表
            let { data } = await axios.get('warehouse/api/wh_vehicle', {params});
            return data;
        },
        async getBoxList(context, params) {                    //集装箱列表
            let { data } = await axios.get('warehouse/api/wh_container', {params});
            return data;
        },
        async downImg(context, params) {                    //下载图片
            let { data } = await axios.post('file_downloads/api/file_zip', params);
            return data;
        },
        async getCarDetaila(context, params) {                    //车辆详情
            let { data } = await axios.get('warehouse/api/wh_vehicle/details', {params});
            return data;
        },
        async getBoxDetaila(context, params) {                    //集装箱详情
            let { data } = await axios.get('warehouse/api/wh_container/details', {params});
            return data;
        },
        async checkBlNo(context, params) {                    //提单号查询权限校验
            let { data } = await axios.get('warehouse/api/auth_check', {params});
            return data;
        },
    }
}