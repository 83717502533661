export default {
    namespaced: true,
    actions: {
        async getBookingAttachmentList(context, params) {                    //资料库列表
            let { data } = await axios.post('booking/api/attachment/list', params);
            return data;
        },
        async getBookingCasInfo(context, params) {                    //CAS 商检名录列表
            let { data } = await axios.post('booking/api/cas/info', params);
            return data;
        },
        async uploadEntrustSave(context, params) {                    //上传委托书 - 新增/修改委托信息
            params._NOCLEAR = true
            let { data } = await axios.post('booking/api/upload_entrust/save', params);
            return data;
        },
        async bookingQuotationNoCheck(context, params) {                    //校验报价编号接口
            let { data } = await axios.post('booking/api/quotation_no/check', params);
            return data;
        },

        async getBookingpacKingType(context, params) {                    //包装类列表
            let { data } = await axios.get('booking/api/packing_type', { params });
            return data;
        },
        async getBookingShippingTerms(context, params) {                    //运输条款
            let { data } = await axios.get('booking/api/shipping_terms', { params });
            return data;
        },
        async getBookingFeeTerms(context, params) {                    //运费条款
            let { data } = await axios.get('booking/api/fee_terms', { params });
            return data;
        },
        async getBookingLinkmanAddress(context, params) {                    //联系人地址
            let { data } = await axios.post('booking/api/address/list', params);
            return data;
        },
        async getBookingEntrustHistoryList(context, params) {                    //历史委托信息
            let { data } = await axios.post('booking/api/entrust_history/list', params);
            return data;
        },
        async getCompanyFileAndInvoice(context, params) {                    //判断公司有没有证书和开票信息
            let { data } = await axios.post('admin/api/company/file_and_invoice', params);
            return data;
        },
        async getPortList(context, params) {                    //港口信息
            let { data } = await axios.post('admin_freight/api/port/keyword', params);
            return data;
        },
        async getConpanyList(context, params) { //获取船公司信息
            let { data } = await axios.post('freight/api/shipping_company/list', params);
            return data;
        },
        async getUnno(context, params) { //unno
            let { data } = await axios.get('freight/api/dangerous/info_list', {params});
            return data;
        },
        async getDestination(params) {                 //推荐目的港
            let { data } = await axios.post('freight/api/recommend_port/info', params);
            return data;
        },
        async getcurrencyList(params) {                 //币种列表
            let { data } = await axios.get('basic/api/currency/list', {params});
            return data;
        },
        async sellMakeEntrustSave(context, params) {  //销售代订舱 - 新增/修改委托信息
            params._NOCLEAR = true
            let { data } = await axios.post('booking/api/sell_make/client_entrust', params);
            return data;
        },
        async cooperativePartnerQuery(context, params) {  //订舱合作伙伴查询
            let { data } = await axios.post('booking/api/cooperative_partner/query', params);
            return data;
        },
        async sellMakeEntrustEdit(context, params) {  //销售代订舱 - 新增委托信息
            params._NOCLEAR = true
            let { data } = await axios.post('booking/api/sell_update/client_entrust', params);
            return data;
        },
        async getNewEntrustFee(context, params) {                    //销售订舱查费用
            let { data } = await axios.post('booking/api/mew_entrust_fee/query', params);
            return data;
        },
        async getEntrustFeeList(context, params) {                    //销售订舱查费用 V2
            let { data } = await axios.post('booking/api/new_entrust_fee/query', params);
            return data;
        },
        async getSellToClientQuotation(context, params) {                    //查询销售对特定客户报价列表
            let { data } = await axios.post('booking/api/sell_to_client/quotation', params);
            return data;
        },
        async getSellToClientLclFreight(context, params) {                    //订舱拼箱查运价
            let { data } = await axios.post('booking/api/booking/lcl_freight', params);
            return data;
        },
        async seaOrderDetails(context, params) {                    //查询海运订单详情
            let { data } = await axios.post('booking/api/sea_order/details', params);
            return data;
        },
        async getWarehouseList(context, params) {                    //仓库列表查询
            let { data } = await axios.post('booking/api/warehouse/list', params);
            return data;
        },
        async getUnnoList(context, params) {                    //危险品列表
            let { data } = await axios.post('booking/api/dangerous_list', params);
            return data;
        },
        async addShippingConsultings(context, params) {                    //新增舱位咨询
            params._NOCLEAR = true
            let { data } = await axios.post('shipping_consultings/api/shipping_consultings', params);
            return data;
        },
        async getossUserList(context, params) {                    //获取后台人员信息接口
            let { data } = await axios.get('user/api/oss_user/list', {params});
            return data;
        },
        async getUserOssCompanyList(context, params) {                    //获取销售对应后台账号所在的公司列表
            let { data } = await axios.get('admin_permission/api/organization_structure/user_company', {params});
            return data;
        },
    }
}