<template>
    <div class="Form_footer">
      <span class="left">第三方账号</span>
      <div class="right" @click="gotoWxlogin">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/icons/weixin%20%281%29.png" alt="">
        <span>微信</span>
      </div>
    </div>
</template>
<script>
    export default {
        methods:{
          gotoWxlogin() {
            const redirect_uri = encodeURI(`${window.location.origin}/wxLogin`);
            window.location.href = `https://wx.ailaworld.com?appIdType=EXP&redirect_url=${redirect_uri}`;
          },
        }
    }
</script>
<style lang="less" scoped>
.Form_footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding: 0 33px;
  margin-top:24px;
  border-top:1px solid #DCDFE6;
  .left{
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #415058;
    line-height: 20px;
  }
  .right{
    cursor: pointer;
    span{
      height: 17px;
      font-size: 12px;
      font-weight: 400;
      color: #909399;
      line-height: 17px;
    }
    img{
      width: 30px;
      margin-right:5px;
    }
  }
}
</style>