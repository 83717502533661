export default {
  navConfig: {
    logoUrl:
      "https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/3.0Resource/yxzLogo.png",
    bgColor: "#4A5A81",
    textColor: "#fff",
    activeTextColor: "#fff",
  },
  accountConfig: {
    notUseWx: true,
    notFavorite: true,
  },
  pageConfig: {
    companyPage: {
      url: "/companyDetail",
      isEncode: true,
    },
  },
};
