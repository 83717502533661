<template>
    <div class="tc mationDialog">
        <div class="title">你还有简历未完善，赶快去完善吧！</div>
        <div class="">完善后将会有更多的公司关注到你</div>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    }
}
</script>
<style lang="less" scoped>
.mationDialog{
    font-size: 14px;
    .title{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}
}

</style>