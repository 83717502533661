import { render, staticRenderFns } from "./navMenuItem.vue?vue&type=template&id=6bbd985a&scoped=true"
import script from "./navMenuItem.vue?vue&type=script&lang=js"
export * from "./navMenuItem.vue?vue&type=script&lang=js"
import style0 from "./navMenuItem.vue?vue&type=style&index=0&id=6bbd985a&prod&scoped=true&lang=less"
import style1 from "./navMenuItem.vue?vue&type=style&index=1&id=6bbd985a&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bbd985a",
  null
  
)

export default component.exports