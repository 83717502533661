import Vue from 'vue'
import {baseConsoleRoutes} from '~bac/router'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [{
    path: '/test',                                              //首页
    name: 'test',
    component: resolve => require(['~hbs/views/test'], resolve),
  },
    {
      path: '/',                                              //首页
      name: 'index',
      component: resolve => require(['~hbs/views/index'], resolve),

    },
    {
      path: '/downloadSection',                                              //首页
      name: 'downloadSection',
      component: resolve => require(['~hbs/views/index/downloadSection/index'], resolve),
    },
    {
      path: '/activitys',                                              //首页--活动
      name: 'activitys',
      component: resolve => require(['~hbs/views/index/activitys/index'], resolve),
      children: []
    },
    {
      path: '/memberShip',                                              //申请入会
      name: 'memberShip',
      component: resolve => require(['~hbs/views/index/memberShip/index'], resolve),
      children: [
        {
          path: '/',
          name: 'applyFor',
          component: resolve => require(['~hbs/views/index/memberShip/flow'], resolve)
        }, {
          path: '/memberShip/applyFor',
          name: 'applyFor',
          component: resolve => require(['~hbs/views/index/memberShip/applyFor'], resolve)
        }]
    },
    {
      path: '/college',                                  //物贸学院
      name: 'college',
      component: resolve => require(['~hbs/views/college/index'], resolve)
    },
    {
      path: '/college_detail',                                  //物贸学院 详情
      name: 'college_detail',
      component: resolve => require(['~hbs/views/college/detail'], resolve)
    },
    {
      path: '/positionDetail',                                //职位详情
      name: 'positionDetail',
      component: resolve => require(['~hbs/views/position/detail'], resolve)
    },
    {
      path: '/survey',                                              //协会概况
      name: 'survey',
      component: resolve => require(['~hbs/views/survey'], resolve),
      children: [{
        path: '/',
        name: 'brief',
        component: resolve => require(['~hbs/views/survey/brief'], resolve)
      }, {
        path: '/survey/director',
        name: 'director',
        component: resolve => require(['~hbs/views/survey/director'], resolve)
      }, {
        path: '/survey/framework',
        name: 'framework',
        component: resolve => require(['~hbs/views/survey/framework'], resolve)
      }, {
        path: '/survey/leader',
        name: 'leader',
        component: resolve => require(['~hbs/views/survey/leader'], resolve)
      }, {
        path: '/survey/supervisor',
        name: 'supervisor',
        component: resolve => require(['~hbs/views/survey/supervisor'], resolve)
      }, {
        path: '/survey/member',
        name: 'member',
        component: resolve => require(['~hbs/views/survey/member'], resolve)
      }, {
        path: '/survey/constitution',
        name: 'constitution',
        component: resolve => require(['~hbs/views/survey/constitution'], resolve)
      }, {
        path: "/survey/memorabilia",
        name: 'memorabilia',
        component: resolve => require(['~hbs/views/survey/memorabilia'], resolve)
      }, {
        path: "/survey/structure",
        name: 'structure',
        component: resolve => require(['~hbs/views/survey/structure'], resolve)
      }]
    }, {
      path: '/companyCatalog',//会员名录
      name: 'companyCatalog',
      component: resolve => require(['~hbs/views/company/companyCatalog'], resolve)
    }, {
      path: '/companyContactList',
      name: 'companyContactList',
      component: resolve => require(['~hbs/views/company/companyContactList'], resolve)
    }, {
      path: '/companyDetail',
      name: 'companyDetail',
      component: resolve => require(['~hbs/views/company/companyDetail'], resolve)
    }, {
      path: '/sign',
      name: 'sign',
      component: resolve => require(['~hbs/views/users/sign'], resolve),
      meta: {
        layout: 'sign'
      }
    },
    {
      path: '/forgetPassword',
      name: 'forgetPassword',
      component: resolve => require(['~hbs/views/users/forgetPassword'], resolve),
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      component: resolve => require(['~hbs/views/users/resetPassword'], resolve),
    },
    {
      path: '/signUp',
      name: 'signUp',
      component: resolve => require(['~hbs/views/users/signUp'], resolve),
      meta: {
        layout: 'sign'
      }
    }, {
      path: '/activeAccount',
      name: 'activeAccount',
      component: resolve => require(['~hbs/views/users/activeAccount'], resolve),
    }, {
      path: '/memberCenter',
      name: 'memberCenter',
      component: resolve => require(['~hbs/views/memberCenter'], resolve)
    },
    {
      path: "/creditAssessment",//信用评估页面
      name: "creditAssessment",
      component: resolve => require(['~hbs/views/creditAssessment'], resolve)
    },
    {
      path: "/creditDetail",//信用评估详情页面
      name: "creditDetail",
      component: resolve => require(['~hbs/views/creditAssessment/detail'], resolve)
    },
    {
      path: "/basicInfo",//完善信息
      name: "basicInfo",
      meta:{
        layout:'empty'
      },
      component: resolve => require(['~hbs/views/users/basicInfo'], resolve)
    },
    {
      path: "/contactUs",//完善信息
      name: "contactUs",
      component: resolve => require(['~hbs/views/contactUs/index'], resolve)
    },
    {
      path: "/detail",//完善信息
      name: "detail",
      component: resolve => require(['~hbs/views/index/detail'], resolve)
    },
    {
      path: "/memberInfo",
      name: "memberInfo",
      component: resolve => require(['~hbs/views/index/detail/companyInfo'], resolve)
    },
    {    // 风险
      path: "/warningList",
      name: "warningList",
      component: resolve => require(['~hbs/views/warningList/index'], resolve)
    },
    {
      path: '/handleInitialParams',
      name: 'handleInitialParams',
      meta: {
        layout: 'empty'
      },
      component: resolve => require(['~hbs/views/users/initPage'], resolve)
    },
    {
      path: '/member/memberDirectory/:id',//会员专区-会员名录
      name: 'memberDirectory',
      component: resolve => require(['~hbs/views/member/memberDirectory'], resolve)
    },
    {
      path: '/member/interests/vice',//会员专区-会员名录-副会长单位
      name: 'vice',
      component: resolve => require(['~hbs/views/member/interests/vice'], resolve)
    },
    {
      path: '/member/interests/standing',//会员专区-会员名录-常务理事单位
      name: 'standing',
      component: resolve => require(['~hbs/views/member/interests/standing'], resolve)
    },
    {
      path: '/member/interests/director',//会员专区-会员名录-理事单位
      name: 'director',
      component: resolve => require(['~hbs/views/member/interests/director'], resolve)
    },
    {
      path: '/member/interests/member',//会员专区-会员名录-会员单位
      name: 'member',
      component: resolve => require(['~hbs/views/member/interests/member'], resolve)
    },
    {
      path: '/centre/party/branch',//党支部架构
      name: 'branch',
      component: resolve => require(['~hbs/views/centre/party/branch'], resolve)
    },
    {
      path: '/talents/recruitment',//招聘信息
      name: 'recruitment',
      component: resolve => require(['~hbs/views/talents/recruitment'], resolve)
    },
    {
      path: '/talents/library',//人才库
      name: 'library',
      component: resolve => require(['~hbs/views/talents/library'], resolve)
    },
    {
      path: '/contactUs/association',//协会联系方式
      name: 'library',
      component: resolve => require(['~hbs/views/contactUs/association'], resolve)
    },
    {
      path: '/contactUs/message',//留言箱
      name: 'association',
      component: resolve => require(['~hbs/views/contactUs/message'], resolve)
    },

  ].concat(baseConsoleRoutes).concat([{
    path: "**",
    redirect: "/"
  }]),
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0}
  }
})
