<template>
  <div class="navMenu">
    <div class="navContent">
        <div class="topHeader">
          <div class="content_area">
            <div class="fr clearfix topSearch">
              <div class="fl routerLink">
                <span>欢迎您！ </span>
                <span v-if="!IS_LOGIN">
                  <router-link to="/sign" class="goLogin">
                    <span>登录</span> </router-link
                  >
                  <router-link to="/signUp" class="goRegister">
                    <span>注册</span>
                  </router-link>
                </span>
                <span v-else>
                  <el-popover
                    placement="bottom-start"
                    trigger="click"
                    v-model="visible"
                  >
                    <div class="navMenuselfAccountInfo">
                      <p class="textOverflow">
                        姓名：{{
                          USER_INFO.name_en
                            | priorFormat(USER_INFO.name_zh, LOCALE)
                        }}
                      </p>
                      <p class="textOverflow">
                        职位：{{
                          USER_INFO.job_title_en
                            | priorFormat(USER_INFO.job_title_zh, LOCALE)
                        }}
                      </p>
                      <p class="textOverflow">
                        邮箱：{{ USER_INFO.email | textFormat }}
                      </p>
                      <p class="textOverflow">
                        手机号：{{ USER_INFO.mobile | textFormat }}
                      </p>
                    </div>

                    <div class="navMenuselfAccountInfoBtn">
                      <el-button
                        type="danger"
                        size="small"
                        @click="
                          visible = false;
                          logOut();
                        "
                        >退出登录</el-button
                      >
                    </div>
                    <img
                      :src="USER_INFO.avatar || defaultAvatar"
                      alt
                      class="userAvatar cursor"
                      slot="reference"
                    />
                  </el-popover>
                  <router-link to="/console/account/userInfo" style="color:#fff">控制台</router-link>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="navMenuLink">
          <div class="content_area clearfix">
            <div class="fl pageLogo">
              <img src="../../assets/img/newLogo.png" alt=""
              style="width:auto;height: 60px;"
              />
            </div>
            <div class="fr meau">
              <navMenuItem />
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import searchCompany from "~hbs/components/company/unit/searchCompany";
import navMenuItem from "~hbs/components/layouts/navMenuItem";

export default {
  components: { searchCompany,navMenuItem },
  data() {
    return {
      visible: false,
      defaultAvatar:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/headportrait.png",
      activeIndex: "/",
      menuLogo:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hbs/logo.png",
      searchVal: "",
      zhReg: /^[\u4e00-\u9fa5]+$/i,
      dialogVisible: false,
    };
  },
  methods: {
    logOut() {
      this.$store.commit("baseStore/CLEAR_USER_INFO");
      this.$router.push("/sign");
    },
    // 搜索框
    searchContent() {
      if (this.searchVal) {
        this.$router.push({
          path: "/companyCatalog",
          query: {
            parameter: this._encode({
              searchVal: this.searchVal,
            }),
          },
        });
      } else {
        this.$router.push("/companyCatalog");
      }
    },
    async querySearchCompanyName(queryString, cb) {
      // if (queryString.length < 3) {
      //   cb([]);
      //   return;
      // }
      let params = {};
      params.company_name = queryString;
      // params.certificate = 20;
      params.source = this.PJSource;
      params.usage=2
      params.is_vip=1
      // params.genre_ids = this.genreType;
      let res = await this.$store.dispatch(
        "baseStore/company_associateSearch",
        params
      );
      let values;
      if (this.zhReg.test(queryString)) {
        values = res.data.map((item) => {
          item.value = item.name_zh;
          return item;
        });
      } else {
        // 输入是英文或者是数字
        values = res.data.map((item) => {
          if (item.name_en) {
            // 返回数据中，英文有值
            item.value = item.name_en;
          } else {
            item.value = item.name_zh;
          }
          return item;
        });
      }
      cb(values);
    },
    // handleActive(path) {
    //   if (path.indexOf("survey") != -1) {
    //     this.activeIndex = path.substring(0, 7);
    //   } else {
    //     this.activeIndex = path;
    //   }
    // },
    // selectMenu(index, path) {
    //   if (index == "") {
    //     this.dialogVisible = true;
    //   }
    // },
  },
  // watch: {
  //   $route(nv) {
  //     this.handleActive(nv.path);
  //     // if (this.$route.query.parameter) {
  //     //   this.searchVal = this._decode(nv.query.parameter).searchVal;
  //     // } else {
  //     //   this.searchVal = "";
  //     // }
  //   },
  // },
  mounted() {},
};
</script>

<style scoped lang="less">
.navMenu {
  width: 100%;
  .userAvatar {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15px;
  }
  height: 100px;
  width: 100%;
  position: relative;
  .navContent {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 0px;
    left: 0px;
    .content_area {
      position: relative;
      .logo {
        position: absolute;
        top: 0px;
        left: 0px;
      }
    }
    .topSearch {
      line-height: 40px;
      .routerLink {
        margin-left: 32px;
        color: #fff;
      }
      .iconSearch {
        margin-right: 5px;
      }
    }
  }
  .topHeader {
    height: 40px;
    background: #4A5A81;
    overflow: hidden;
  }
  .navMenuLink {
    height: 60px;
    background: #fff;
    .pageLogo{
      // margin-top: 10px;
    }
  }
}
.userImg {
  margin-right: 10px;
  img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }
}
.goLogin {
  color: #fff;
}
.goRegister{
  display: block;
  color: #fff;
  float: right;
  background: #E3954F;
  width: 76px;
  text-align: center;
  margin-left: 15px;
}
/deep/.el-input--mini .el-input__inner {
  border-radius: 15px;
}
.openDialog {
  height: 100px;
  line-height: 100px;
}
/**
导航条的样式
*/
.el-menu-atf {
  border-bottom: none;
}
.el-menu {
  background: transparent;
}
.submenu {
  width: 110px;
}
/deep/ .el-submenu__icon-arrow{
  color: #999 !important;
}
// /deep/.el-menu-item {
//   background-color: transparent !important;
//   color: #fea2a4 !important;
// }
// /deep/.el-menu-item:hover {
//   outline: 0 !important;
//   color: #fff !important;
// }
// /deep/.el-menu-item.is-active {
//   color: #fff !important;
//   background: transparent !important;
// }
// /deep/.el-menu--horizontal > .el-submenu .el-submenu__title,
// .el-menu--horizontal > .el-menu-item {
//   border-bottom: none;
//   &.is-active {
//     color: #fff;
//     border-bottom: 0;
//     background: transparent;
//   }
// }
// /deep/.el-menu--horizontal > .el-submenu {
//   .el-submenu__title {
//     color: #fea2a4 !important;
//     border-bottom: none !important;
//     i {
//       color: #fea2a4;
//     }
//     &:hover {
//       background: transparent;
//       color: #fff !important;
//     }
//   }
// }
// /deep/.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
//   color: #fff !important;
// }
// .el-menu--horizontal .el-menu .el-menu-item.is-active,
// .el-menu--horizontal .el-menu .el-submenu.is-active > .el-submenu__title {
//   background: #fef7f7 !important;
//   color: #f45b5b;
// }
/deep/.el-menu.el-menu--horizontal {
  border-bottom: none;

}
 &.el-menu--horizontal>.el-menu-item.is-active{
   border-bottom: 4px solid #E3954F;
 }
</style>
<style lang="less">
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 130px !important;
  text-align: left!important;
}
.navMenuselfAccountInfo {
  p {
    line-height: 28px;
  }
  .navMenuselfAccountInfoBtn {
    margin-top: 8px;
  }
}
</style>
