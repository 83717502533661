import Vue from 'vue'
import baseStore from '~/baseStore'
import baseConsole from '~bac/store'
import API_Sign from './API_sign'
import API_ymp_company from './API_company'
import API_freight from './API_freight'
import API_bookingSpace from './API_bookingSpace'
import API_fcl_bookingSpace from './API_fcl_bookingSpace'
import API_warehouse from './API_warehouse'
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        isApplyShow: false,
        fullBookingDetails:null
    },
    mutations: {
        saveFullBookingDetails(state,pathName){
            state.fullBookingDetails = pathName;
        },
    },
    actions: {

    },
    modules: { baseStore, baseConsole, API_Sign, API_ymp_company, API_freight, API_bookingSpace ,API_fcl_bookingSpace,API_warehouse}
})

export default store
