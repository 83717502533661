import Vue from 'vue'

import baseStore from '~/baseStore'
import API_company from './API/company'
import API_booth from './API/booth'
import API_user from './API/user'
import API_index from './API/index'
import API_position from './API/position'
import baseConsole from '~bac/store'
import API_collection from './API/collection'
import API_myApply from './API/myApply'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        meeting_id: 60,
    },
    mutations: {

    },
    actions: {

    },

    modules: { baseStore, API_company, API_booth, API_user, API_index, baseConsole,API_position,API_collection,API_myApply }
})

export default store
