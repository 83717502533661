<template>
  <div>
    <el-menu class="el-menu-atf"
             :unique-opened="true"
             mode="horizontal"
             :default-active="activeIndex"
             menu-trigger="click"
             @select="selectMenu"
    >
      <template v-for="(item,index) in navData">
        <el-submenu :key="index" :index="item.path" v-if="item.children" popper-class="myHomeMenuPopper">
          <template slot="title">
            {{ item.title }}
          </template>
          <template v-for="(ee,vv) in item.children">
            <el-submenu :key="vv" :index="ee.path" v-if="ee.children" popper-class="myHomeMenuPopper">
              <template slot="title">
                {{ ee.title }}
              </template>
              <template v-for="(eee,vvv) in ee.children">
                <el-submenu :key="vvv" :index="eee.path" v-if="eee.children" popper-class="myHomeMenuPopper">
                  <template slot="title">
                    {{ eee.title }}
                  </template>
                  <template v-for="(eeee,vvvv) in eee.children">
                    <el-menu-item :key="vvvv" :index="eeee.path">
                      {{ eeee.title }}
                    </el-menu-item>
                  </template>
                </el-submenu>
                <el-menu-item :key="vvv" v-else :index="eee.path">
                  {{ eee.title }}
                </el-menu-item>
              </template>
            </el-submenu>
            <el-menu-item :key="vv" :index="ee.path" v-else>
              {{ ee.title }}
            </el-menu-item>
          </template>
        </el-submenu>
        <el-menu-item :key="index" :index="item.path" v-else>
          {{ item.title }}
        </el-menu-item>
      </template>
    </el-menu>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="300px"
        :close-on-click-modal="true"
    >
      <div class="tc openDialog">暂未开放</div>
    </el-dialog>
    <el-dialog
        title="提示"
        :visible.sync="isMumber"
        width="300px"
        :close-on-click-modal="true"
    >
      <div class="tc openDialogs">
        <span class="blue" @click="goToMember">成为会员</span>,解锁专属权益
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "navMenuItem",
  data() {
    return {
      activeIndex: "/",
      dialogVisible: false,
      isMumber: false,
      navData: [
        {
          title: '首页',
          path: "/"
        },
        {
          title:'企业',
          path:"/companyCatalog"
        },
        {
          title:"职位",
          path:"/talents/recruitment"
        },
        // {
        //   title:"人才库",
        //   path:"/talents/library"
        // },
      ]
    }
  },
  watch: {
    $route(nv) {
      this.handleActive(nv.path);

    },
  },
  mounted() {

  },
  methods: {
    selectMenu(index, path) {
      if (index.indexOf('http') !== -1) {
        window.open(index)
        return
      }
      // if (index === '/talents/library') {
      //   if (!this.USER_INFO || !this.USER_INFO.aila_no) {
      //     this.isMumber = true
      //     return
      //   }
      // }
      if (index == "") {
        this.dialogVisible = true;
        return
      }
      this.$router.push(index)
    },
    handleActive(path) {
      if (path.indexOf("survey") !== -1) {
        this.activeIndex = path.substring(0, 7);
      } else {
        this.activeIndex = path;
      }
    },
    goToMember() {
      this.isMumber = false;
      this.$router.push('/memberShip')
    }
  }
}
</script>

<style scoped lang="less">
/**
导航条的样式
*/
/deep/ .el-menu-atf {
  border-bottom: none;
}

/deep/ .el-menu {
  background: transparent;
}

/deep/ .submenu {
  width: 110px;
}

/deep/ .el-submenu__icon-arrow {
  color: #999 !important;
}

/deep/ .el-menu.el-menu--horizontal {
  border-bottom: none;

}

/deep/ .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 4px solid #E3954F;
}

/deep/ .el-menu-item {
  padding: 0 10px;
  width: 96px;
  font-size: 16px;
  text-align: center;
}
li.el-menu-item {
    font-size: 16px;
}
/deep/ .el-submenu__title {
  width: 120px;
  font-size: 16px;
  padding: 0 10px;
}


.blue {
  color: #409eff;
  cursor: pointer;
}

.openDialogs {
  height: 100px;
  line-height: 100px;
}
</style>
<style lang="less">
.myHomeMenuPopper {
  /deep/ .el-menu-item {
    font-size: 16px;
  }
}
</style>
